<template>
  <v-app>
    <v-app-bar
      app
      height="60"
      dense
      :color="isDark ? 'rgba(0,0,0,.7)':'rgba(0,0,0,.0)'"
      class="app-bar"
    >
      <v-row class="width-per-100 height-per-100 ma-0 justify-center">
        <div class="width-per-100 height-per-100 d-flex justify-space-between menu">
          <div style="width: 260px;">
            <v-btn
                plain elevation="0"
                height="100%"
                min-width="100"
                to="/"
                class="pl-0 logo-btn"
                :ripple="false"
            >
              <v-img
                  alt="logo"
                  contain
                  src="https://h5.ophyer.cn/official_website/other/metaverse-logo.png"
                  height="30"
                  width="192"
              />
            </v-btn>
          </div>
          <div class="d-flex justify-end width-per-100">
            <v-hover
                v-for="(link,index) in links"
                :key="index">
              <div class="height-per-100 d-inline mx-6">
                <v-btn
                    height="100%"
                    light depressed plain
                    :color="currentMenu === link.router ? '#0568FD':''"
                    :ripple="false"
                    class="px-0"
                    :class="currentMenu === link.router ? 'menu-checked':''"
                    @click.stop="handleMenuClick(link, false, link)"
                >
                  <div class="menu-title">{{ link.title }}
                    <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                  </div>
                </v-btn>
              </div>
            </v-hover>
          </div>
        </div>
      </v-row>
    </v-app-bar>
    <router-view></router-view>
    <v-footer
      color="#111214"
      height="50"
      width="100%"
      class="justify-center align-start"
      v-if="$route.path.split('/')[3] !== 'Course'"
    >
      <div class="footer-box">
        <ul>
          <li @click="$router.push({ name: 'MetaverseHome' })">官网</li>
          <li @click="handleWindow">开发者</li>
          <li @click="$router.push({ name: 'MetaverseCourse' })">体验</li>
        </ul>
        <p>© COPYRIGHT 2021 . ALL RIGHTS RESERVED. 北京掌中飞天科技股份有限公司版权所有 京公网安备11010502037637号 京ICP09083236号</p>
      </div>
    </v-footer>
  </v-app>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: 'metaverse',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/",
        children: []
      },
      {
        title: "开发者",
        router: "/develop/Home",
        isNewWindow: true,
        children: []
      },
      {
        title: "体验",
        router: "/Course",
        children: []
      },
    ],
    currentMenu: "/",
  }),
  computed: {
    ...mapState(["officialWebsite"]),
    isDark() {
      if(this.$route.path.split('/')[1] === 'Course') {
        return true;
      } else {
        if(this.officialWebsite.showReturnTip >= 60){
          return true;
        }
        return false;
      }
    }
  },
  watch: {
    $route(to) {
      // const menuInfo = to.path.split("/");
      // this.currentMenu = "/metaverse/" + menuInfo[2]+'/'+ menuInfo[3];
      this.currentMenu = to.path;
    },
  },
  mounted() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.currentHover = '';
    })

    // const menuInfo = this.$route.path.split("/");
    // this.currentMenu = "/metaverse/" + menuInfo[2]+'/'+ menuInfo[3];
    this.currentMenu = this.$route.path;
    if(this.$route.path.split("/").length === 2) {
      this.$router.push({ name:'MetaverseHome' })
    }
  },
  methods: {
    handleMenuClick: function (menu, clickChildren, content){
      if(menu.children.length !== 0 && !clickChildren) return;
      if(content && content.router){
        if(content.isNewWindow){
          window.open(window.location.protocol + "//" + window.location.host + content.router)
        }else {
          this.$router.push(content.router)
        }
      }
      this.showMenuChildren = false;
    },
    handleWindow() {
      window.open(window.location.protocol + "//" + window.location.host + '/develop/Home')
    }

  }
};
</script>


<style lang="scss" scoped>
::v-deep .v-application--wrap {
  width: 100% !important;
}
.v-application {
  background: #191b1f !important;
}

.app-bar{
  line-height: 1;
  box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1) !important;
  box-shadow: none !important;
  z-index: 1000 !important;
  
  ::v-deep .v-toolbar__content, .v-toolbar__extension {
    padding: 0 !important;
  }
  .v-toolbar__content {
    padding: 0 80px !important;
    .logo-btn{
      padding-left: 60px !important;
      .v-btn__content{
        opacity: 1 !important;
        height: 100%;
      }
    }
    .menu{
      margin: 0 auto;
      padding-right: 60px;
      .v-btn:not(.v-btn--round).v-size--default{
        min-width: unset;
      }
      .v-btn__content {
        opacity: unset !important;
      }
      .d-inline:nth-last-child(1) {
        margin-right: 0 !important;
      }
    }
    .menu-hover {
      border-bottom: 2px solid #333333;
    }
    .menu-title {
      margin-top: 10px;
      font-size: 16px;
      height: 30px;
      position: relative;
      color: rgba(255, 255, 255, 0.7);
      .menu-btn-active{
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);
        height: 3px;
        width: 30px;
        background-color: #fff;
      }
    }
    .menu-checked .menu-title {
      color: #fff !important;
    }
  }
}

.footer-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    li {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      line-height: 22px;
      margin-right: 32px;
      cursor: pointer;
      position: relative;
      &:hover {
        border-bottom: 1px solid #979797;
      }
      &::after {
        content: "";
        position: absolute;
        right: -16px;
        top: 5px;
        width: 1px;
        height: 12px;
        z-index: 1;
        background: #fff;
      }
    }
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 0;
  }
}

</style>